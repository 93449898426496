import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Doors.css'; // Make sure this CSS file is properly set up

function Doors() {
  return (
    <div className="doors-container">
      <Link to="/">Back to Home</Link> {/* Replace the anchor tag with Link */}
      <h1>Door Selection and Installation Guide</h1>

      <section>
        <h2>Installing a Door</h2>
        <p>Master the process of door installation with this comprehensive video guide. Learn how to properly install a door, ensuring it's level, secure, and functions correctly.</p>
        
        <div className="video-affiliate-wrapper">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/uagFiXqbD20"
        title="Installing a Door"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
    </iframe>

    {/* Button for Amazon affiliate link */}
    <a href="https://www.amazon.com/Rhino-Strong-Commercial-Professional-Inflatable/dp/B07751F8Y3?crid=275VWDYZEG3XI&keywords=door+installation+tool&qid=1704692729&sprefix=door+intallation+tool%2Caps%2C150&sr=8-36&linkCode=ll1&tag=mrfixit09-20&linkId=cecf07068b3b476267e2c69b775e1d0d&language=en_US&ref_=as_li_ss_tl" 
       target="_blank" 
       rel="noopener noreferrer"
       style={{ display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '4px 2px', cursor: 'pointer' }}>
        Check out This Door Installation Tool on Amazon
    </a>
</div>

      </section>


      <section>
        <h2>Introduction</h2>
        <p>Choosing and installing the right doors is a vital aspect of home improvement. This guide will help you understand the key considerations for selecting doors that complement your home and provide a step-by-step installation process.</p>
      </section>

      <section>
        <h2>Picking the Right Style</h2>
        <p>Matching the House Aesthetic: Select a door style that complements your home. Consider a classic six-panel door for a traditional look or a smooth-faced door for a more modern appeal.</p>
      </section>

      <section>
        <h2>Replacing a Door: Prehung or Slab</h2>
        <p>Prehung Doors: These are ideal for uneven or damaged frames as they come with their own frame. Slab Door Replacement: This involves replacing just the door itself and is more suitable for frames that are in good condition.</p>
      </section>

      <section>
        <h2>Advantages of Prehung Doors</h2>
        <p>Installing a prehung door can be simpler and more time-efficient, especially in cases where the old frame is out of alignment. It ensures that the door hardware is in the correct position.</p>
      </section>

      <section>
        <h2>Steps for Installing a Door</h2>
        <h3>Prehung Door Installation</h3>
        <p>Includes removing the old door, preparing the opening, placing the prehung door, securing it, and installing trim and hardware.</p>
        <h3>Slab Door Installation</h3>
        <p>Entails measuring the old door, preparing the slab door, attaching hardware, hanging the door, and making necessary adjustments for alignment.</p>
      </section>

      <section>
        <h2>Conclusion</h2>
        <p>Whether opting for a prehung door or replacing just the slab, the right tools and attention to detail are crucial for a successful installation. This guide aims to equip you with the knowledge needed for a smooth door installation process.</p>
      </section>
    </div>
  );
}

export default Doors;
