import React from "react";

function VideoCard({ title, videoId }) {
  const videoSrc = `https://www.youtube.com/embed/${videoId}`;
  return (
    <div>
      <h2>{title}</h2>
      <iframe 
        title={`Video: ${title}`} // Providing a descriptive title for accessibility
        width="560" 
        height="315" 
        src={videoSrc} 
        frameBorder="0" 
        allowFullScreen>
      </iframe>
    </div>
  );
}

export default VideoCard;

