import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Kitchens.css'; // Make sure this CSS file is properly set up

function Kitchens() {
  return (
    <div className="kitchens-container">
      <Link to="/">Back to Home</Link> {/* Replace the anchor tag with Link */}
      <h1>Complete Guide to Kitchen Remodeling</h1>

      <section>
        <h2>Exploring Kitchen Upgrade Options</h2>
        <p>Enhance your kitchen remodeling knowledge with this informative video and related product recommendation. This section provides valuable insights and resources for your kitchen upgrade.</p>

        <div className="video-affiliate-wrapper">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/IOWcSbWW4Zw"
        title="Exploring Kitchen Upgrade Options"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
    </iframe>

    {/* Button for Amazon affiliate link */}
    <a href="https://www.amazon.com/XINQIAO-Support-Premium-Capacity-Cabinet/dp/B09B75QRHP?crid=14U9VKT9M078W&keywords=3rd%2Barm&qid=1704691616&sprefix=third%2Barm%2Caps%2C163&sr=8-1-spons&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=mrfixit09-20&linkId=13a76fdaf7e23e9d18e4581c7861c21c&language=en_US&ref_=as_li_ss_tl" 
       target="_blank" 
       rel="noopener noreferrer"
       style={{ display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '4px 2px', cursor: 'pointer' }}>
        Check out This Third Hand Tool on Amazon
    </a>
</div>

      </section>

      <section>
        <h2>Introduction</h2>
        <p>Kitchen remodeling can transform the heart of your home. Whether you're refinishing existing cabinets or considering new ones, understanding the options and processes is key. This guide will cover cabinet refinishing, new cabinet selection, and other remodeling tips.</p>
      </section>

      <section>
        <h2>Refinishing Kitchen Cabinets</h2>
        <p>Using Lacquer for a Professional Finish: Lacquer is often preferred for refinishing cabinets due to its durable and smooth finish, resembling store-bought quality. Preparation and Application: The process involves thorough sanding to remove old finishes and applying lacquer for a sleek look. We'll guide you through each step to achieve the best results.</p>
      </section>

      <section>
        <h2>Alternative Refinishing Products</h2>
        <p>Options Beyond Lacquer: There are other products available for refinishing cabinets. We'll discuss the pros and cons of each, helping you make an informed decision. The Work Involved: Refinishing cabinets is labor-intensive, requiring sanding down to bare wood. We'll provide tips to streamline the process.</p>
      </section>

      <section>
        <h2>Installing New Cabinets</h2>
        <p>When to Consider Replacement: Sometimes, replacing cabinets is more feasible for a fresh look or a new layout. We'll explore scenarios where new cabinets are a better option. Choosing Cabinets Within Budget: Cabinets come in various price ranges, including affordable, ready-to-assemble options. We'll discuss how to choose cabinets that meet your aesthetic and budgetary needs.</p>
      </section>

      <section>
        <h2>Planning Your Kitchen Remodel</h2>
        <p>Design and Layout: Consider the layout and design of your kitchen. We'll provide insights into optimizing space, functionality, and style. Selecting Appliances and Countertops: Choosing the right appliances and countertops is crucial. We'll cover what to consider for a harmonious kitchen design.</p>
      </section>

      <section>
        <h2>Conclusion</h2>
        <p>A kitchen remodel can significantly enhance your home's value and functionality. Whether you’re refinishing cabinets or installing new ones, proper planning and execution are key to achieving your dream kitchen.</p>
      </section>
    </div>
  );
}

export default Kitchens;
