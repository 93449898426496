import React from 'react';
import { Link } from 'react-router-dom';
import './Bathrooms.css'; // Make sure this CSS file is properly set up

function Bathrooms() {
  return (
    <div className="bathrooms-container">
      <Link to="/">Back to Home</Link> {/* Replace the anchor tag with Link */}
      <h1>Bathroom Remodeling Guide</h1>

      <section>
        <h2>Hot Mopping a Shower Pan: Step-by-Step Guide</h2>
        <p>Discover the process of hot mopping a shower pan, a crucial step in waterproofing your bathroom. This video offers a detailed walkthrough from start to finish, demonstrating professional techniques for a leak-proof shower pan.</p>
        
        <div className="video-affiliate-wrapper">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/zuZ-n2ZAx9Y"
        title="Hot Mop a Shower Pan (From Start to Finish)"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
    </iframe>

    {/* Button for Amazon affiliate link */}
    <a href="https://www.amazon.com/Keonjinn-Bathroom-Anti-Fog-Dimmable-Horizontal/dp/B085DTTZXY?content-id=amzn1.sym.96232a40-8dad-410b-aa59-aa3d5b2415c7%3Aamzn1.sym.96232a40-8dad-410b-aa59-aa3d5b2415c7&cv_ct_cx=bathroom%2Bmirror%2Bwith%2Blight&keywords=bathroom%2Bmirror%2Bwith%2Blight&pd_rd_i=B085DTTZXY&pd_rd_r=751765c4-91f2-41a6-81ba-095929ceb486&pd_rd_w=oHxZD&pd_rd_wg=ZXr0X&pf_rd_p=96232a40-8dad-410b-aa59-aa3d5b2415c7&pf_rd_r=6WYD0CYV2AWKHQ190WSM&qid=1704691383&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-3-364cf978-ce2a-480a-9bb0-bdb96faa0f61-spons&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&sp_csd=d2lkZ2V0TmFtZT1zcF9zZWFyY2hfdGhlbWF0aWM&th=1&linkCode=ll1&tag=mrfixit09-20&linkId=bd80057881f2c3f3b5b1a621ad54884e&language=en_US&ref_=as_li_ss_tl" 
       target="_blank" 
       rel="noopener noreferrer"
       style={{ display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '4px 2px', cursor: 'pointer' }}>
        Check out This LED Bathroom Mirror on Amazon
    </a>
</div>

      </section>

      <section>
        <h2>Introduction</h2>
        <p>Embarking on a bathroom remodel can be both exciting and daunting. Whether you're aiming for a simple refresh or a complete overhaul, understanding the key aspects of bathroom remodeling is crucial. This guide will walk you through the essential steps and considerations, helping you transform your bathroom into a functional and stylish space.</p>
      </section>

      <section>
        <h2>Design Planning and Considerations</h2>
        <h3>Developing Your Vision</h3>
        <p>Focus your bathroom redesign on a specific style and color scheme that harmonizes with your home's overall aesthetic. Incorporate a niche for soap and shampoo, a bench seat for added comfort, and a single-handle valve for convenient water control. Choose fixtures and layout that enhance either a modern or traditional ambiance, tailored to your preference.</p>

        <h3>Layout Considerations</h3>
        <p>Altering the layout can significantly impact your project's complexity, especially when it involves moving plumbing fixtures. Consider whether you want to keep the existing layout or create a new one. Moving fixtures might require more extensive plumbing work, depending on whether your home is on a slab or a raised foundation.</p>
      </section>

      <section>
        <h2>Plumbing Challenges and Solutions</h2>
        <p>Understanding Your Foundation: The type of foundation your home has — slab or raised — affects the ease of making plumbing changes. In raised foundation homes, accessing pipes can be easier, while slab foundations may require more invasive work. Evaluate these factors when planning layout changes. Consulting with a plumbing expert can provide valuable insights into the feasibility and cost implications of your desired changes. They can advise on the best approach for relocating fixtures like sinks, toilets, and showers.</p>
      </section>

      <section>
        <h2>Bathtub vs. Shower: Making the Right Choice</h2>
        <p>The Bathtub Advantage: While many modern designs favor stand-up showers, consider the benefits of having at least one bathtub in your home. Bathtubs are excellent for families with babies or small children and can be a selling point if you plan to sell your home in the future.</p>
        <p>Shower Considerations: If you opt for a stand-up shower, you'll need to decide on the type of shower base — whether a pre-made shower pan or a custom-built one. The custom option involves processes like using liners or hot mopping to ensure waterproofing.</p>
      </section>

      <section>
        <h2>Wall Options for Showers and Baths</h2>
        <p>Cement backer board installation or floating walls technique are two options for shower wall construction. Cement backer board provides a water-resistant tile base. Floating walls, a mix of cement and sand applied directly on walls, offer a similar water-resistant foundation for tiling. Both methods support various finishes like acrylic panels or waterproof tiles.</p>

      </section>

      <section>
        <h2>Detailed Step-by-Step Process</h2>
        <p>Demolition: Start by removing old fixtures, tiles, and drywall, with a plan for waste disposal and recycling. Plumbing Adjustments: Make necessary plumbing modifications according to your new layout, which may involve pipe rerouting or new fixture installations.</p>
<p>Waterproofing: Essential for shower areas to prevent water damage and mold. Begin by applying water-resistant paper, like roofing paper, on the studs. Install cement backer board over this layer, ensuring to use cement board mesh tape on the joints. Before tiling, apply a waterproofing product like Redguard over the cement board for added protection. When preparing the shower pan, protect the weep holes with gravel before creating your slope to ensure proper drainage.</p>
<p>Wall and Floor Finishing: Select materials that suit your style and are appropriate for humid conditions, focusing on ease of cleaning and durability.</p>
<p>Installation of Fixtures: Install your chosen fixtures such as bathtub, shower, sink, and toilet, ensuring all connections are secure and leak-free.</p>

      </section>

      <section>
        <h2>Conclusion</h2>
        <p>Remodeling your bathroom can significantly enhance your home's comfort and value. By carefully planning and considering the key elements discussed in this guide, you can ensure a successful bathroom renovation project.</p>
      </section>
    </div>
  );
}

export default Bathrooms;


