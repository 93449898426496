import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Flooring.css'; // Make sure this CSS file is properly set up

function Flooring() {
  return (
    <div className="flooring-container">
      <Link to="/">Back to Home</Link> {/* Replace the anchor tag with Link */}
      <h1>Complete Guide to Flooring Options</h1>

      <section>
        <h2>Installing a Waterproof Floor</h2>
        <p>Learn the process of installing a waterproof floor, ideal for areas prone to moisture. This video provides a step-by-step guide to ensure a durable and moisture-resistant flooring installation.</p>
        
        <div className="video-affiliate-wrapper">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/7pilCL-8dyw"
        title="Installing a Waterproof Floor"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
    </iframe>

    {/* Button for Amazon affiliate link */}
    <a href="https://www.amazon.com/NAACOO-Weight-Square-Hammering-Durable/dp/B0C5XJJTV3?crid=121WJHIMO1YED&keywords=Flooring+tool&qid=1704691828&sprefix=flooring+tool%2Caps%2C152&sr=8-9&linkCode=ll1&tag=mrfixit09-20&linkId=569a656c370bc171462c8f3a07220ecd&language=en_US&ref_=as_li_ss_tl" 
       target="_blank" 
       rel="noopener noreferrer"
       style={{ display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '4px 2px', cursor: 'pointer' }}>
        Check out This Flooring Tool on Amazon
    </a>
</div>

      </section>

      <section>
        <h2>Introduction</h2>
        <p>Choosing the right flooring is crucial to both the functionality and aesthetics of your home. This guide will cover various flooring options, including tile, floating floors, and wood, providing insights into their longevity and suitability for different spaces.</p>
      </section>

      <section>
        <h2>Tile Flooring</h2>
        <p>Characteristics and Benefits: Tile flooring, typically made from ceramic or porcelain, is known for its durability and water resistance. It's ideal for bathrooms, kitchens, and high-traffic areas. Longevity and Maintenance: Tile can last for decades with proper installation and care. Maintenance involves regular cleaning and occasionally resealing grout lines.</p>
      </section>

      <section>
        <h2>Floating Floors</h2>
        <p>What Are Floating Floors: Floating floors, such as laminate or luxury vinyl planks, are not nailed or glued down. They're easy to install and great for areas where you want a wood-like appearance without the cost or maintenance of real wood. Durability and Use Cases: Floating floors are durable, resistant to moisture (especially vinyl), and suitable for families and pets. They're ideal for living rooms, hallways, and basements.</p>
      </section>

      <section>
        <h2>Wood Flooring</h2>
        <p>Types of Wood Floors: Hardwood floors are timeless and add value to your home. Options include solid hardwood and engineered wood, each with unique characteristics. Longevity and Care: With proper care, hardwood floors can last a lifetime. They require refinishing every few years and are sensitive to moisture and scratches.</p>
      </section>

      <section>
        <h2>Factors to Consider</h2>
        <p>Room Usage: Choose a flooring type based on the room's function. Moisture-prone areas require water-resistant flooring like tile or vinyl. Aesthetic Appeal: Consider the style of your home. Traditional homes may benefit from the warmth of wood, while modern homes may suit tile or laminate. Installation and Budget: Evaluate your DIY skills for installation and consider your budget. Some flooring options are more cost-effective and easier to install than others.</p>
      </section>

      <section>
        <h2>Conclusion</h2>
        <p>Your choice in flooring can significantly impact your home's look, feel, and functionality. Understanding the differences between various flooring types helps you make an informed decision that aligns with your lifestyle and design preferences.</p>
      </section>
    </div>
  );
}

export default Flooring;
