import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Plumbing from './Plumbing';
import Electrical from './Electrical';
import Concrete from './Concrete';
import Dyrwall from './Drywall';
import Painting from './Painting';
import Stucco from './Stucco';
import Framing from './Framing';
import Roofing from './Roofing';
import Siding from './Siding';
import Windows from './Windows';
import Bathrooms from './Bathrooms';
import Kitchens from './Kitchens';
import Flooring from './Flooring';
import Fences from './Fences';
import Molding from './Molding';
import Doors from './Doors';
import Handyman from './Handyman';
// Import other components as needed

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/plumbing" element={<Plumbing />} />
        <Route path="/electrical" element={<Electrical />} />
        <Route path="/concrete" element={<Concrete />} />
        <Route path="/Drywall" element={<Dyrwall />} />
        <Route path="/Painting" element={<Painting />} />
        <Route path="/Stucco" element={<Stucco />} />
        <Route path="/Framing" element={<Framing />} />
        <Route path="/Roofing" element={<Roofing />} />
        <Route path="/Siding" element={<Siding />} />
        <Route path="/Windows" element={<Windows />} />
        <Route path="/Bathrooms" element={<Bathrooms />} />
        <Route path="/Kitchens" element={<Kitchens />} />
        <Route path="/Flooring" element={<Flooring />} />
        <Route path="/Fences" element={<Fences />} />
        <Route path="/Molding" element={<Molding />} />
        <Route path="/Doors" element={<Doors />} />
        <Route path="/Handyman" element={<Handyman />} />
        {/* Define routes for other components here */}
      </Routes>
    </Router>
  );
}

export default App;
