import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Fences.css'; // Ensure this CSS file is properly set up

function Fences() {
  return (
    <div className="fences-container">
      <Link to="/">Back to Home</Link> {/* Replace the anchor tag with Link */}
      <h1>Complete Guide to Building Fences</h1>

      <section>
        <h2>Building a Block Wall Fence</h2>
        <p>Learn the detailed process of building a block wall fence, a sturdy and aesthetic choice for property boundaries. This video provides comprehensive instructions for constructing a durable block wall.</p>
        
        <div className="video-affiliate-wrapper">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/P8U7AnmCmQ8"
        title="Building a Block Wall"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
    </iframe>

    {/* Button for Amazon affiliate link */}
    <a href="https://www.amazon.com/Johnson-Level-Tool-40-6517-Self-Leveling/dp/B003825F0M?crid=3V19I6ZK9UQVP&keywords=laser%2Blevel%2B360%2Bself%2Bleveling&qid=1704692220&sprefix=laser%2Bleve%2Caps%2C178&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=mrfixit09-20&linkId=e96388decca86588b4926e4b34006d8e&language=en_US&ref_=as_li_ss_tl" 
       target="_blank" 
       rel="noopener noreferrer"
       style={{ display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '4px 2px', cursor: 'pointer' }}>
        Check out This Laser Level Tool on Amazon
    </a>
</div>

      </section>

      <section>
        <h2>Introduction</h2>
        <p>Building a fence can enhance both the privacy and aesthetic appeal of your property. This guide will walk you through the essentials of fence construction, from choosing the right materials to the construction process itself.</p>
      </section>

      <section>
        <h2>Choosing Wood for Fences</h2>
        <p>Popular Wood Types: Redwood and cedar are top choices for fences due to their natural beauty and durability. Treatment and Maintenance: Wooden fences require treatment to withstand the elements. We'll cover the different types of treatments like stains and sealants to protect your fence from weathering and decay.</p>
      </section>

      <section>
        <h2>Planning Your Fence</h2>
        <p>Determining Boundaries: Before construction, it’s important to determine the exact boundaries of your property. Design Considerations: Consider the height, style, and functionality of your fence. We'll explore various design options to match your home's aesthetic and meet your privacy needs.</p>
      </section>

      <section>
        <h2>Materials and Tools Needed</h2>
        <p>List of Materials: We'll provide a comprehensive list of materials needed, including wood, posts, concrete, nails, and screws. Tools for the Job: From hammers and saws to post hole diggers, we'll detail the essential tools for fence building.</p>
      </section>

      <section>
        <h2>Building the Fence: Step-by-Step</h2>
        <p>Setting Fence Posts: Learn how to set fence posts correctly – an essential step for a sturdy, long-lasting fence. Assembling the Fence: We'll guide you through attaching rails to the posts, followed by fence boards or panels. Tips for ensuring even spacing and alignment will be provided.</p>
      </section>

      <section>
        <h2>Finishing Touches</h2>
        <p>Painting or Staining: A final coat of paint or stain not only adds beauty but also protection to your fence. We'll discuss application techniques for a professional finish.</p>
      </section>

      <section>
        <h2>Conclusion</h2>
        <p>Building a fence is a rewarding project that adds value and character to your property. With the right preparation, materials, and techniques, you can construct a fence that stands the test of time and complements your home.</p>
      </section>
    </div>
  );
}

export default Fences;
