import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Concrete.css'; // Ensure you create and set up this CSS file

function Concrete() {
  return (
    <div className="concrete-container">
      <Link to="/">Back to Home</Link> {/* Replace the anchor tag with Link */}
      <h1>Concrete Work Guide</h1>

      <section>
        <h2>Essential Concrete Tools</h2>
        <p>Discover the essential tools for working with concrete. This video provides insights into the tools needed for various concrete projects.</p>
        <div className="video-affiliate-wrapper">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/XILRlOH4GhA"
        title="Concrete Tools Overview"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
    </iframe>

    {/* Button for Amazon affiliate link */}
    <a href="https://www.amazon.com/Goldblatt-Finishing-Extruded-Magnesium-Organized/dp/B08LQ4M6SW?crid=69VN5HSBOQH3&keywords=concrete+tools&qid=1704689501&sprefix=concrete+tool%2Caps%2C180&sr=8-4-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=ll1&tag=mrfixit09-20&linkId=d0b7089256a184ec68e487ad3439432e&language=en_US&ref_=as_li_ss_tl" 
       target="_blank" 
       rel="noopener noreferrer"
       style={{ display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '4px 2px', cursor: 'pointer' }}>
        Check out These Concrete Tools on Amazon
    </a>
</div>

      </section>

      <section>
        <h2>Introduction</h2>
        <p>Working with concrete is an essential skill for various construction projects, from laying driveways to building patios. This guide provides a comprehensive overview of the process, from preparation to finishing techniques.</p>
      </section>

      <section>
        <h2>Preparation for Concrete Pouring</h2>
        <h2>Concrete Thickness</h2>
    <h3>Why Thickness Matters:</h3>
    <ul>
        <li><strong>Structural Integrity:</strong> The thickness of the concrete slab contributes significantly to its load-bearing capacity and durability.</li>
        <li><strong>Standard Thickness:</strong> For most residential projects like sidewalks and patios, a minimum thickness of four inches is standard.</li>
        <li><strong>Heavier Loads:</strong> In areas expecting heavier loads, such as driveways or garage floors, a greater thickness—often six inches or more—is recommended to accommodate the additional weight.</li>
    </ul>

    <h2>Preparing the Site</h2>
    <h3>Digging Footings:</h3>
    <ul>
        <li><strong>Square and Level:</strong> Ensure that footings are squared off and level. This is crucial for the stability of the structure they will support.</li>
        <li><strong>Depth and Size:</strong> The depth and size of the footings should comply with local building codes, which vary based on the soil type and the structure’s load.</li>
        <li><strong>Debris Removal:</strong> Clear out all debris, leaves, and trash from the footings. Any organic material left in the footing can decompose and compromise the concrete's integrity.</li>
    </ul>

    <h3>Staking and String Lines:</h3>
    <ul>
        <li><strong>Marking the Area:</strong> Use stakes and string lines to outline the area where the concrete will be poured. This helps in visualizing the project and assists in keeping the framing straight.</li>
        <li><strong>Setting Grade:</strong> String lines also aid in setting the grade – the level and slope of the ground. This is particularly important for drainage and preventing water accumulation.</li>
    </ul>

    <h3>Using Laser Levels for Precision:</h3>
    <ul>
        <li><strong>Accuracy in Leveling:</strong> Laser levels are invaluable for ensuring that the entire area is perfectly level or has the correct slope.</li>
        <li><strong>Consistency:</strong> They provide a constant point of reference, which is crucial when working over large areas.</li>
    </ul>

    <h2>Groundwork and Framing</h2>
    <h3>Soil Preparation:</h3>
    <ul>
        <li><strong>Compaction:</strong> The soil beneath the concrete should be compacted to prevent settling and cracking.</li>
        <li><strong>Gravel Layer:</strong> Adding a layer of gravel can aid in drainage and further stabilize the ground.</li>
    </ul>

    <h3>Building the Frame:</h3>
    <ul>
        <li><strong>Containment and Shape:</strong> Frames, typically made of wood, are used to contain the concrete and give it the desired shape.</li>
        <li><strong>Support and Reinforcement:</strong> Ensure the frame is strong and well-supported to withstand the pressure of the concrete without shifting.</li>
    </ul>

    <h3>Reinforcement:</h3>
    <ul>
        <li><strong>Adding Strength:</strong> Using rebar or wire mesh within the concrete can significantly increase its tensile strength.</li>
        <li><strong>Preventing Cracks:</strong> Proper reinforcement helps in distributing the load and preventing cracks, especially in high-traffic areas.</li>
    </ul>
      </section>

      <section>
        <h2>Pouring Concrete</h2>
        <h2>Volume Considerations</h2>
    <ul>
        <li>Concrete trucks typically deliver up to 10 cubic yards of concrete.</li>
        <li>When ordering small volumes, say only a couple of yards, be aware of the 'short load' or 'short yard' fees. These fees can add a few hundred dollars to your order, making small quantities disproportionately expensive.</li>
    </ul>

    <h3>Economical Tips</h3>
    <ul>
        <li>To avoid these fees, it's crucial to accurately calculate the amount of concrete needed for your project.</li>
        <li>Consider consolidating small projects or coordinating with neighbors to increase the order size, making it more cost-effective.</li>
    </ul>

    <h2>Towable Concrete Mixers</h2>
    <h3>An Alternative for Small Quantities</h3>
    <p>For projects requiring only a few yards of concrete, renting a towable concrete mixer can be a practical solution. Rental centers often offer these mixers, where they fill it with the required amount of concrete, which you can then tow to your site.</p>

    <h3>Process</h3>
    <ul>
        <li>Once at the job site, you'll need to work quickly to unload and use the concrete before it begins to set.</li>
        <li>After use, it's crucial to clean the mixer thoroughly to avoid additional cleaning fees or damage charges.</li>
    </ul>

    <h2>Hand Mixing Concrete</h2>
    <h3>For Minimal Quantities</h3>
    <p>If you're dealing with very small amounts of concrete, hand mixing using a shovel and wheelbarrow can be a viable option.</p>

    <h3>Mixing Process</h3>
    <ul>
        <li>The key to hand mixing is getting the right ratio of cement, water, and aggregate (sand and gravel).</li>
        <li>Mix the dry ingredients thoroughly first, then gradually add water until you achieve the desired consistency.</li>
    </ul>

    <h3>Pouring and Spreading</h3>
    <ul>
        <li>Once mixed, pour the concrete immediately and spread it evenly to form a strong base.</li>
        <li>Proper leveling and consolidation are crucial to eliminate air pockets and ensure uniform strength.</li>
    </ul>
      </section>

      <section>
        <h2>Understanding Concrete Cracking</h2>
        <h2>Causes of Concrete Cracking</h2>
    <h3>Temperature-Induced Cracking</h3>
    <ul>
        <li><strong>Thermal Movement:</strong> Concrete undergoes expansion when heated and contraction when cooled. This movement can lead to cracking, particularly when temperature changes are abrupt or extreme.</li>
        <li><strong>Mitigation Strategies:</strong> To accommodate thermal expansion and contraction, construction practices often involve the use of expansion joints in concrete structures.</li>
    </ul>

    <h3>Curing and Shrinkage</h3>
    <ul>
        <li><strong>Evaporation and Tensile Stress:</strong> As concrete cures, water evaporates from the mix, leading to shrinkage. This shrinkage can create tensile stresses within the concrete, resulting in cracks.</li>
        <li><strong>Control Joints:</strong> The strategic placement of control joints is a common technique to manage shrinkage cracks, guiding them along predetermined paths and minimizing their impact on the structure.</li>
    </ul>

    <h2>Assessing and Addressing Crack Severity</h2>
    <h3>Distinguishing Crack Types</h3>
    <ul>
        <li><strong>Normal Cracking:</strong> Hairline cracks are a regular occurrence in concrete and typically do not affect its structural integrity.</li>
        <li><strong>Excessive Cracking:</strong> Larger, deeper, or rapidly developing cracks might be indicative of more serious issues, such as foundational problems or improper mixing and pouring techniques.</li>
    </ul>

    <h2>Determining Responsibility for Cracks</h2>
    <h3>Contractual Agreements and Workmanship</h3>
    <ul>
        <li>The responsibility for concrete cracks can often depend on the specific terms outlined in contractual agreements between the parties involved. These agreements may specify standards and expectations for the construction process.</li>
        <li>In cases where poor workmanship, such as improper concrete mix or inadequate site preparation, is evident, responsibility might be attributed to the contractor, depending on the contract's stipulations.</li>
    </ul>

    <h3>External Factors</h3>
    <ul>
        <li>Certain causes of cracking, like unexpected environmental changes or inherent variations in material properties, may fall outside the contractor’s control. These factors can contribute to cracking despite adherence to recommended construction practices.</li>
    </ul>
      </section>

      <section>
        <h2>Finishing Concrete</h2>
        <p>After pouring, the concrete must be finished to create a smooth, durable surface. This involves floating, rodding, edging, and troweling or burning. Each step is crucial for achieving the desired surface texture and durability.</p>
      </section>

      <section>
        <h2>Curing and Drying</h2>
        <h2>Strength Development</h2>
    <p>Concrete strength development is not instantaneous. It begins right after pouring and continues over time, with the most significant strength gains occurring in the first 30 days. During this period, the chemical reactions between water and cement (hydration) are actively contributing to the hardening and strengthening of the concrete.</p>

    <h2>Maintaining Moisture for Optimal Curing</h2>
    <h3>Why Moisture is Crucial</h3>
    <p>The hydration process, crucial for concrete strength, requires an adequate amount of water. Insufficient moisture can impede this process, leading to weaker concrete.</p>

    <h3>Methods to Maintain Moisture</h3>
    <ul>
        <li><strong>Misting with a Garden Hose:</strong> One of the most effective ways to maintain moisture is by gently misting the concrete surface with a garden hose a few times a day. This method is particularly beneficial in hot or windy conditions, where water evaporation is rapid.</li>
        <li><strong>Consistency in Misting:</strong> It's important to mist the concrete regularly and uniformly. Avoid over-watering, as excess water can weaken the surface.</li>
    </ul>

    <h2>Control Joints and Form Stripping</h2>
    <h3>Control Joints</h3>
    <p>As concrete cures, it naturally contracts, which can lead to cracking. To manage this, control joints are cut into the concrete. These joints create weakened lines that encourage cracks to form along them, rather than randomly across the surface, thus preserving the appearance and integrity of the concrete.</p>

    <h3>Stripping Forms</h3>
    <p>Form stripping refers to the removal of wooden or metal structures that hold the concrete in shape while it sets. Timing is critical in form stripping. Removing the forms too early can lead to shape loss, while leaving them on for too long can hinder the curing process, especially at the sides and bottom.</p>

    <h2>Strength Development Over Time</h2>
    <p>In the initial 30 days, concrete can achieve approximately 50-75% of its total strength, depending on the mix and environmental conditions. The strength continues to increase gradually over time, reaching its maximum (which can be several thousand psi) over the course of several months to a year.</p>
      </section>

      <section>
        <h2>Decorative Concrete Techniques</h2>
        <p>For an artistic touch, techniques like acid staining and stamping can transform concrete into a decorative element. Acid staining creates vibrant colors and patterns, while stamping can mimic textures like stone or brick.</p>
      </section>

      <section>
        <h2>Conclusion</h2>
        <p>Concrete work is rewarding and results in durable, long-lasting structures. Understanding each step from preparation to finishing ensures a successful project, whether for functional or decorative purposes.</p>
      </section>
    </div>
  );
}

export default Concrete;
