import React from "react";
import VideoCard from "./VideoCard";
import { Link } from "react-router-dom";
import './Home.css'; // Ensure this CSS file exists in the same directory

function Home() {
  const videos = [
    { 
      title: "Gas Meter Valve - How to Turn On/Off", 
      videoId: "L3eL47BvupU", 
      amazonLink: "https://www.amazon.com/HORUSDY-Adjustable-2-Piece-10-Inch-Crescent/dp/B0C5LHTXHP?crid=28RKO7EN0N2KX&keywords=crescent+wrench&qid=1704693428&sprefix=crescent+wrench%2Caps%2C187&sr=8-3-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=ll1&tag=mrfixit09-20&linkId=8f02dff16b7440e61ffe74c01b44ee9f&language=en_US&ref_=as_li_ss_tl" 
    },
    { 
      title: "Self Leveling Spacers", 
      videoId: "_ilF4RVaO8U", 
      amazonLink: "https://www.amazon.com/Tile-Leveling-System-Plier-Installation/dp/B07XYS9JLW?crid=2UD055O0UTP7P&keywords=Self-leveling%2Btile%2Bclips&qid=1704693505&sprefix=self-leveling%2Btile%2Bclips%2Caps%2C158&sr=8-5&th=1&linkCode=ll1&tag=mrfixit09-20&linkId=a6a134dffebedad70b1f6122fe20ea56&language=en_US&ref_=as_li_ss_tl" 
    },
    { 
      title: "How to Use Type A PEX", 
      videoId: "UBu229YVvwI", 
      amazonLink: "https://www.amazon.com/2532-22-M12-FUEL-Cordless-Expansion/dp/B08VNP4Z98?crid=GEBHHPA4GM9R&keywords=pro+pex+expander+tool&qid=1704693696&sprefix=pro+pex+expander+tool%2Caps%2C162&sr=8-18&linkCode=ll1&tag=mrfixit09-20&linkId=f4b74812b87036208130cf41e5f31404&language=en_US&ref_=as_li_ss_tl" 
    },
    { 
      title: "Painting High Vaulted Ceilings", 
      videoId: "wt3NRllFkbU", 
      amazonLink: "https://www.amazon.com/Extender-Extension-Gripper-Painting-Decoration/dp/B0CKMGCYXX?crid=1HOQ7L99XFVJP&keywords=paintbrush+extender&qid=1704693796&sprefix=paintbrush+extender%2Caps%2C152&sr=8-18&linkCode=ll1&tag=mrfixit09-20&linkId=48dbb449be9b37de66414b03138a2659&language=en_US&ref_=as_li_ss_tl" 
    },
    { 
      title: "Most OVERLOOKED House Maintenance", 
      videoId: "5MSBYxNkzps", 
      amazonLink: "https://www.amazon.com/Milwaukee-Sawzall-Brushless-Cordless-Reciprocating/dp/B08WG2HC81?crid=2HXFUHYSZA40M&keywords=sawzall&qid=1704693920&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=sawzal%2Caps%2C155&sr=8-16&linkCode=ll1&tag=mrfixit09-20&linkId=c4b620bc9a0440b5dcfbdbd8ec660899&language=en_US&ref_=as_li_ss_tl" 
    }
  ];

  return (
    <div className="home-container">
      {/* Navigation Links */}
      <div>
        <h2>Explore Categories</h2>
        <ul className="category-links">
          <li><Link to="/plumbing" className="category-link">Plumbing Projects</Link></li>
          <li><Link to="/electrical" className="category-link">Electrical Projects</Link></li>
          <li><Link to="/concrete" className="category-link">Concrete Projects</Link></li>
          <li><Link to="/drywall" className="category-link">Drywall Projects</Link></li>
          <li><Link to="/Painting" className="category-link">Painting Projects</Link></li>
          <li><Link to="/Stucco" className="category-link">Stucco Projects</Link></li>
          <li><Link to="/Framing" className="category-link">Framing Projects</Link></li>
          <li><Link to="/Roofing" className="category-link">Roofing Projects</Link></li>
          <li><Link to="/Siding" className="category-link">Siding Projects</Link></li>
          <li><Link to="/Windows" className="category-link">Windows Projects</Link></li>
          <li><Link to="/Bathrooms" className="category-link">Bathrooms Projects</Link></li>
          <li><Link to="/Kitchens" className="category-link">Kitchens Projects</Link></li>
          <li><Link to="/Flooring" className="category-link">Flooring Projects</Link></li>
          <li><Link to="/Fences" className="category-link">Fences Projects</Link></li>
          <li><Link to="/Molding" className="category-link">Molding Projects</Link></li>
          <li><Link to="/Doors" className="category-link">Doors Projects</Link></li>
          <li><Link to="/Handyman" className="category-link">Handyman Projects</Link></li>
          {/* Add more categories as needed */}
        </ul>
      </div>

      <h1>DIY Home Projects</h1>
      <div className="video-list">
        {videos.map(video => (
          <div key={video.videoId} className="video-affiliate-wrapper">
            <VideoCard title={video.title} videoId={video.videoId} />
            <a href={video.amazonLink}
               target="_blank" 
               rel="noopener noreferrer"
               style={{ display: 'block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '10px 0', cursor: 'pointer' }}>
              Check out Related Product on Amazon
            </a>
          </div>
        ))}
      </div>

      {/* Disclaimer */}
      <div className="disclaimer">
        <p>
        Disclaimer:
The content provided on this website and associated videos is for informational, educational, and entertainment purposes only. The information is presented in good faith, but no warranty is given, nor results guaranteed, and we do not assume any legal liability or responsibility for the accuracy, completeness, or usefulness of any information disclosed. Following any instructions or relying on information from this site is done at your own risk.

This site is not represented by licensed contractors or professional builders, and the projects, techniques, and tools presented in these videos and articles may not be suitable for all ages or skill levels. The safety measures and standards set forth in the content are not comprehensive and are not a substitute for professional advice. Always seek the advice of a professional with any questions you may have regarding a project or construction task.

By using this site, you acknowledge and agree that you are responsible for following all local codes, regulations, and laws and for ensuring any work complies with the relevant standards in your area. The website will not be liable for any damages, injuries, or losses incurred as a result of any attempts to follow the tutorials, suggestions, or information provided.

Always use personal discretion, follow all safety guidelines, and exercise caution when engaging in any project or task. We encourage you to do your research, be prepared, and stay informed with your local building codes and safety regulations.

        </p>
      </div>
    </div>
  );
}

export default Home;