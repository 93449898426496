import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Plumbing.css';

function Plumbing() {
  useEffect(() => {
    const typewriterText = "Check out this PEX crimping tool on Amazon";
    const container = document.getElementById('typewriter-container');
    container.textContent = typewriterText;
  }, []);

  return (
    <div className="plumbing-container">
      <Link to="/">Back to Home</Link>
      <h1>Complete Guide to Plumbing Essentials</h1>

      {/* Section with video and affiliate link */}
      <section className="video-affiliate-section">
        <div className="video-container">
          {/* Video content */}
          <h2>Installing PEX: Attaching and Crimping Fittings</h2>
          <p>Learn the basics of working with PEX piping, including how to properly attach and crimp fittings...</p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/2U6mD0cpDkg"
            title="Installing PEX: How to Attach and Crimp Fittings"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div>

        <div className="affiliate-container">
          {/* Affiliate text link */}
          <a 
            href="https://www.amazon.com/SharkBite-23251-Copper-Tubing-Fittings/dp/B007AGMM1W?crid=27L6DE5N1FAMM&keywords=pex+crimping+tool&qid=1704618854&sprefix=pex%2Caps%2C223&sr=8-47&linkCode=ll1&tag=mrfixit09-20&linkId=84fc28dafafe87d034443f5702ab767b&language=en_US&ref_=as_li_ss_tl"
            target="_blank" 
            rel="noopener noreferrer"
            title="Affiliate Product for Plumbing"
            className="affiliate-text-link"
          >
            <span id="typewriter-container" className="typewriter-text"></span>
          </a>
        </div>
      </section>

      <section>
        <h2>Introduction</h2>
        <p>Understanding plumbing is crucial for maintaining and upgrading your home's infrastructure. This guide will cover key topics from pipe materials to pressure regulation, essential for homeowners and DIY enthusiasts.</p>
      </section>

      <section>
        <h2>Pipe Materials: Galvanized, Copper, PEX</h2>
        <h3>Galvanized Pipes: The Old Standard</h3>
<p><strong>Overview:</strong></p>
<ul>
    <li><strong>Material:</strong> Steel pipes coated with a protective layer of zinc.</li>
    <li><strong>Usage Era:</strong> Predominantly used in homes built before the 1960s.</li>
</ul>
<p><strong>Key Issues:</strong></p>
<ul>
    <li><strong>Corrosion:</strong> Internal corrosion over time leads to restricted water flow and reduced water quality.</li>
    <li><strong>Lifespan:</strong> Typically lasts between 20-50 years but deteriorates due to rust and corrosion.</li>
    <li><strong>Health Concerns:</strong> The corrosion process can release lead and other harmful substances into the water supply.</li>
</ul>

<h3>Transition to Copper and PEX</h3>
<p>This shift signifies advancements in plumbing technology, focusing on enhanced water quality and longevity.</p>

<h3>Copper Pipes: The Durable Choice</h3>
<p><strong>Characteristics:</strong></p>
<ul>
    <li><strong>Material:</strong> A natural, corrosion-resistant metal.</li>
    <li><strong>Types:</strong> Available in rigid and flexible forms.</li>
</ul>
<p><strong>Advantages:</strong></p>
<ul>
    <li><strong>Longevity:</strong> Can exceed 50 years of use.</li>
    <li><strong>Corrosion Resistance:</strong> Offers superior resistance to corrosion and scaling, unlike galvanized pipes.</li>
    <li><strong>Heat Tolerance:</strong> Ideal for hot water and high-temperature applications.</li>
    <li><strong>Recyclability:</strong> An eco-friendly option due to its high recyclability.</li>
</ul>
<p><strong>Considerations:</strong></p>
<ul>
    <li><strong>Cost:</strong> Typically more expensive than PEX.</li>
    <li><strong>Installation:</strong> Installation is more labor-intensive, often requiring soldering.</li>
</ul>

<h3>PEX Pipes: The Modern, Flexible Solution</h3>
<p><strong>Characteristics:</strong></p>
<ul>
    <li><strong>Material:</strong> Flexible plastic piping made from cross-linked polyethylene.</li>
</ul>
<p><strong>Advantages:</strong></p>
<ul>
    <li><strong>Flexibility:</strong> Easily routed through walls, reducing the need for multiple fittings.</li>
    <li><strong>Corrosion Resistance:</strong> Does not corrode, thus eliminating rust contamination risks.</li>
    <li><strong>Ease of Installation:</strong> Quicker and simpler to install compared to copper, which can reduce labor costs.</li>
    <li><strong>Cost-Effectiveness:</strong> Generally more affordable than copper.</li>
</ul>
<p><strong>Lifespan:</strong></p>
<ul>
    <li><strong>Durability:</strong> Expected to last between 40-50 years.</li>
</ul>
<p><strong>Considerations:</strong></p>
<ul>
    <li><strong>UV Sensitivity:</strong> Cannot be exposed to sunlight due to UV sensitivity.</li>
    <li><strong>Temperature Limitations:</strong> Not as suitable for high-temperature applications as copper.</li>
</ul>

<h3>Conclusion</h3>
<p>The decision to use galvanized, copper, or PEX pipes should be based on budget, application, and specific plumbing requirements. While galvanized pipes are less favored due to corrosion and health risks, copper and PEX pipes present more reliable and long-lasting alternatives. Copper is notable for its durability and high-temperature resistance, whereas PEX is a cost-effective and flexible choice suitable for a variety of modern plumbing needs.</p>
      </section>

      <section>
        <h2>Quality of Installation</h2>
        <h2>Ensuring Quality Plumbing Work: Key Practices and Standards</h2>

<ol>
    <li><strong>Correct Pipe Sizing and Placement:</strong>
        <ul>
            <li><strong>Code Compliance:</strong> Following local plumbing codes for pipe diameter and flow rates to prevent issues like low water pressure or drainage problems.</li>
            <li><strong>Strategic Placement:</strong> Ensuring pipes are placed with enough clearance from electrical wiring and other utilities, as specified by code.</li>
        </ul>
    </li>
    <li><strong>Use of Pipe Hangers and Supports:</strong>
        <ul>
            <li><strong>Proper Support:</strong> Installing pipe hangers and supports at recommended intervals to prevent sagging and stress on pipes. Codes often specify the maximum spacing between supports for different types and sizes of pipes.</li>
        </ul>
    </li>
    <li><strong>Precision in Pipe Installation and Jointing</strong>
        <ul>
            <li><strong>Accurate Measurements:</strong> Ensuring pipes are cut to the precise length, allowing for a snug fit without undue stress on connections.</li>
            <li><strong>Deburring Pipes:</strong> Removing burrs after cutting metal pipes to prevent turbulence and potential wear inside the pipe.</li>
        </ul>
    </li>
    <li><strong>Proper Soldering of Copper Pipes:</strong>
        <ul>
            <li><strong>Even Application of Flux:</strong> Applying the right amount of flux before soldering to prevent oxidation and ensure a clean, solid joint.</li>
            <li><strong>Wiping Excess Flux:</strong> Cleaning off excess flux immediately after soldering to prevent corrosion over time.</li>
        </ul>
    </li>
    <li><strong>Ensuring Durability and Leak Prevention</strong>
        <ul>
            <li><strong>Thorough Leak Testing:</strong> Conducting pressure tests on new piping installations to check for leaks.</li>
            <li><strong>Visual Inspections:</strong> Regularly inspecting joints, valves, and connections for signs of corrosion or leaks.</li>
        </ul>
    </li>
    <li><strong>Correct Use of Sealants and Tapes:</strong>
        <ul>
            <li><strong>Teflon Tape and Compound:</strong> Applying Teflon tape or pipe joint compound correctly to threaded connections to ensure a watertight seal.</li>
        </ul>
    </li>
    <li><strong>Maintaining Water Quality and Safety</strong>
        <ul>
            <li><strong>Backflow Prevention:</strong> Installing backflow prevention devices where necessary to protect drinking water from contamination.</li>
        </ul>
    </li>
    <li><strong>Safe Material Handling:</strong>
        <ul>
            <li><strong>Lead-Free Solder:</strong> Using only lead-free solder for potable water lines to avoid health hazards.</li>
        </ul>
    </li>
    <li><strong>Energy Efficiency and Environmental Considerations</strong>
        <ul>
            <li><strong>Heat Preservation:</strong> Properly insulating hot water pipes to reduce heat loss and save energy.</li>
            <li><strong>Freeze Protection:</strong> Insulating pipes in exposed or unheated areas to prevent freezing.</li>
        </ul>
    </li>
</ol>

      </section>

      <section>
        <h2>Drain Lines and Supply Lines</h2>
        <h2>Understanding Drain and Supply Systems</h2>

<h3>Drain Lines</h3>
<ul>
    <li><strong>Purpose:</strong> Designed to carry waste water away from your home.</li>
    <li><strong>Material:</strong> Often made of PVC, ABS, or cast iron.</li>
    <li><strong>Common Issues:</strong> Clogs and blockages due to waste accumulation; pipe corrosion in older systems.</li>
</ul>

<h3>Supply Lines</h3>
<ul>
    <li><strong>Purpose:</strong> Responsible for delivering fresh water into your home.</li>
    <li><strong>Material:</strong> Typically made of copper, PEX, or PVC.</li>
    <li><strong>Orientation:</strong> In many setups, the hot water line is on the left, and the cold water line is on the right when facing a fixture.</li>
    <li><strong>Common Issues:</strong> Leaks due to corroded pipes, loose connections, or high water pressure.</li>
</ul>

<h3>Maintenance Tips for Healthy Plumbing</h3>
<ul>
    <li><strong>Drain Line Maintenance:</strong> Regular cleaning with natural cleaners like baking soda and vinegar to prevent buildup. Be mindful of what goes down your drains, avoiding grease, coffee grounds, and non-biodegradable items.</li>
    <li><strong>Supply Line Maintenance:</strong> Regular inspection for leaks or signs of corrosion, especially in older copper pipes.</li>
</ul>

      </section>

      <section>
        <h2>Pressure Regulators</h2>
        <h2>The Vital Role of Pressure Regulators</h2>

<h3>1. Protecting Plumbing Systems:</h3>
<ul>
    <li><strong>Function:</strong> Pressure regulators are designed to control the water pressure coming into your home from the main water supply.</li>
    <li><strong>Prevention of Damage:</strong> By reducing high pressure, these devices prevent stress on pipes, joints, and fixtures, which can lead to leaks or bursts.</li>
</ul>

<h3>2. Consistent Water Pressure:</h3>
<ul>
    <li><strong>Comfort and Efficiency:</strong> Regulators ensure a steady, comfortable water pressure for showers, faucets, and appliances.</li>
    <li><strong>Energy Savings:</strong> Proper pressure levels can also contribute to energy efficiency, especially in hot water systems.</li>
</ul>

<h2>Adjusting and Replacing Pressure Regulators</h2>

<h3>1. Recognizing When Adjustment is Needed:</h3>
<ul>
    <li><strong>Symptoms:</strong> Fluctuating water pressure, unusually high or low pressure, and noise in pipes can indicate the need for adjustment.</li>
    <li><strong>Pressure Gauge:</strong> Use a pressure gauge to check the current pressure level. Typical home water pressure should be between 40-60 psi.</li>
</ul>

<h3>2. How to Adjust a Pressure Regulator:</h3>
<ul>
    <li><strong>Locating the Regulator:</strong> Typically found where the main water line enters the house.</li>
    <li><strong>Adjustment Process:</strong> Use a wrench to turn the adjustment screw on the regulator. Clockwise increases pressure, while counterclockwise decreases it.</li>
</ul>

<h3>3. Knowing When to Replace:</h3>
<ul>
    <li><strong>Lifespan:</strong> Pressure regulators generally last about 5 to 10 years.</li>
    <li><strong>Persistent Issues:</strong> If problems persist after adjustment or if the regulator is old, replacement may be necessary.</li>
</ul>

<h3>4. Steps for Replacement:</h3>
<ul>
    <li><strong>Shut Off Water:</strong> Turn off the main water supply before starting.</li>
    <li><strong>Remove Old Regulator:</strong> Use wrenches to loosen the connections and remove the old regulator.</li>
    <li><strong>Install New Regulator:</strong> Ensure the new regulator is installed in the correct orientation (indicated by an arrow on the device) and tighten the connections.</li>
</ul>

      </section>

      <section>
        <h2>Modern Plumbing Innovations</h2>
        <h2>Cutting-Edge Plumbing Technologies</h2>

<h3>1. Smart Plumbing Systems:</h3>
<ul>
    <li><strong>Features:</strong> Incorporating sensors and digital controls for leak detection, water temperature regulation, and usage monitoring.</li>
    <li><strong>Benefits:</strong> Enhanced efficiency, early problem detection, and personalized water usage management.</li>
</ul>

<h3>2. Eco-Friendly Solutions:</h3>
<ul>
    <li><strong>Water-Saving Fixtures:</strong> Low-flow toilets, faucets, and showerheads designed to reduce water consumption.</li>
    <li><strong>Greywater Recycling Systems:</strong> Reusing wastewater from sinks and showers for non-potable purposes, promoting sustainable water use.</li>
</ul>

<h2>Innovative Materials</h2>

<h3>1. PEX Piping:</h3>
<ul>
    <li><strong>Advantages:</strong> Flexible, durable, and resistant to scale and chlorine, making it a preferred choice over traditional materials like copper and PVC.</li>
</ul>

      </section>

      <section>
        <h2>When to Call a Professional</h2>
        <h2>DIY Plumbing: When to Consider It</h2>

<h3>1. Simple Repairs and Maintenance:</h3>
<ul>
    <li><strong>Tasks:</strong> Fixing leaky faucets, unclogging drains, or replacing showerheads.</li>
    <li><strong>Benefits:</strong> Cost-effective and usually requires basic tools and skills.</li>
</ul>

<h3>2. Learning Opportunity:</h3>
<ul>
    <li><strong>Educational Value:</strong> Perfect for those looking to enhance their DIY skills with manageable challenges.</li>
</ul>

<h2>Professional Plumbing Assistance: When to Seek Help</h2>

<h3>1. Complex Issues:</h3>
<ul>
    <li><strong>Tasks:</strong> Addressing major leaks, installing new pipes, or dealing with water heater problems.</li>
    <li><strong>Risks:</strong> Mistakes can lead to more significant damage, requiring costly repairs.</li>
</ul>

<h3>2. Code Compliance and Safety:</h3>
<ul>
    <li><strong>Expertise Needed:</strong> Professionals ensure that the work is up to code and safe, avoiding future legal and safety issues.</li>
</ul>

      </section>

      <section>
        <h2>Conclusion</h2>
        <p>Whether you're dealing with aging pipes or considering an upgrade, a solid understanding of plumbing principles is invaluable. This guide aims to provide you with foundational knowledge to help maintain and improve your home's plumbing system.</p>
      </section>
    </div>
  );
}

export default Plumbing;
