import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Drywall.css'; // Make sure this CSS file is properly set up

function Drywall() {
  return (
    <div className="drywall-container">
      <Link to="/">Back to Home</Link> {/* Replace the anchor tag with Link */}
      <h1>Comprehensive Drywall Guide</h1>

      <section>
        <h2>Choosing the Right Drywall Compound</h2>
        <p>Understanding which drywall compound to use is crucial for effective drywall installation and finishing. This video will guide you through the different types of compounds and their best uses.</p>
        <div className="video-affiliate-wrapper">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/HCoPRbvh5Tc"
        title="Use the Correct Drywall Compound"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
    </iframe>

    {/* Button for Amazon affiliate link */}
    <a href="https://www.amazon.com/Goldblatt-5-Piece-Stainless-Finishing-Patching/dp/B08HJ7TLL1?crid=2WQ3D4S5YQWCC&keywords=drywall+tools&qid=1704689756&sprefix=drywall+%2Caps%2C209&sr=8-4-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=ll1&tag=mrfixit09-20&linkId=f0f9167a1551f076eccf884878d2871d&language=en_US&ref_=as_li_ss_tl" 
       target="_blank" 
       rel="noopener noreferrer"
       style={{ display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '4px 2px', cursor: 'pointer' }}>
        Check out These Drywall Tools on Amazon
    </a>
</div>

      </section>

      <section>
        <h2>Introduction</h2>
        <p>Working with drywall is an essential skill for various construction projects. This guide provides a comprehensive overview of the process, from preparation to finishing techniques.</p>
      </section>

      <div class="tree-graph">
    <p>Drywall Types</p>
    <div class="branch">
        <p>Standard White Drywall</p>
        <ul>
            <li>Usage: Residential and commercial</li>
            <li>Composition: Gypsum core, heavy paper</li>
            <li>Features: Cost-effective, easy to install</li>
            <li>Cost: Economical</li>
        </ul>
    </div>
    <div class="branch">
        <p>Green Board Drywall</p>
        <ul>
            <li>Usage: Bathrooms, kitchens, laundry rooms</li>
            <li>Composition: Gypsum core, moisture-resistant paper</li>
            <li>Features: Moisture-resistant, waxed surface</li>
            <li>Cost: Higher than standard</li>
        </ul>
    </div>
    <div class="branch">
        <p>Purple Drywall</p>
        <ul>
            <li>Usage: Basements, bathrooms, kitchens, etc.</li>
            <li>Composition: Gypsum core, moisture & mold-resistant</li>
            <li>Features: Superior moisture and mold resistance, noise reduction</li>
            <li>Cost: Highest among the three</li>
        </ul>
    </div>
</div>



      <section>
        <h2>Cutting Drywall</h2>
        <p>Simple scoring and snapping techniques allow for quick and precise cuts using a utility knife.</p>
      </section>

      <section>
        <h2>Installation Techniques</h2>
        <h2>Drywall Installation Techniques</h2>

    <h3>Screw Pattern and Spacing</h3>
    <ul>
        <li><strong>Edge Spacing:</strong> Screws are placed along the edges of drywall panels at intervals of about 8 to 12 inches to prevent sagging or bulging.</li>
        <li><strong>Center Spacing:</strong> Along the center of the panel, screws are spaced further apart, usually around 16 inches, ensuring even weight distribution and support.</li>
    </ul>

    <h3>Screw Indentation and Drywall Mud</h3>
    <ul>
        <li><strong>Countersunk Screws:</strong> Drywall screws must be countersunk slightly below the surface, allowing for a smooth layer of drywall mud (joint compound) over them.</li>
        <li><strong>Depth Precision:</strong> The depth of the screws is crucial; too deep can compromise drywall integrity, while too shallow prevents proper mud coverage.</li>
    </ul>

    <h2>Specialized Tools for Drywall Installation</h2>
    <h3>Types of Tools</h3>
    <ul>
        <li><strong>Drywall Phillips Bit:</strong> Designed to fit the screw head perfectly and disengage at a specific depth to prevent over-driving.</li>
        <li><strong>Drywall Drills:</strong> Equipped with a drywall Phillips bit, these drills maintain consistent depth with each screw, thanks to a built-in clutch system.</li>
        <li><strong>Screw Guns with Auto-feed Systems:</strong> For faster installation, these guns drive screws at pre-set intervals quickly and accurately.</li>
    </ul>
      </section>

      <section>
        <h2>Sizes of Drywall</h2>
        <p>Available in various thicknesses: 1/4 inch for curved surfaces, 1/2 inch for interior walls, and 5/8 inch for soundproofing and fire resistance.</p>
      </section>

      <section>
        <h2>Additional Details and Tips</h2>
        <h2>Drywall Installation Techniques</h2>

    <h3>Taping Seams</h3>
    <ul>
        <li><strong>Self-Adhesive Tape:</strong> Applied directly to seams with a layer of joint compound over it.</li>
        <li><strong>Paper Tape:</strong> Involves applying a layer of mud, setting the paper tape on it, and then covering it with more mud.</li>
        <li><strong>Bazooka Tool:</strong> Used for faster applications, applies mud and paper tape simultaneously, suitable for larger projects.</li>
    </ul>

    <h3>Mudding Tools</h3>
    <ul>
        <li><strong>4-inch Trowel:</strong> Ideal for applying mud over tape and small patchwork.</li>
        <li><strong>6-inch and 12-inch Trowels:</strong> Used for feathering out the mud over larger areas.</li>
        <li><strong>Corner Trowel:</strong> Designed for clean finishes in corners.</li>
    </ul>

    <h3>Sanding</h3>
    <ul>
        <li><strong>Sanding Block (100-Grit):</strong> Traditional method for manually sanding drywall seams.</li>
        <li><strong>Drywall Sanding Sponge:</strong> Flexible and easy to use, especially around corners.</li>
        <li><strong>Electric Sander with Vacuum:</strong> Efficient for larger projects and reduces dust.</li>
        <li><strong>Imperfection Detection:</strong> Any imperfections felt by hand will likely be visible after painting, emphasizing the importance of thorough sanding.</li>
    </ul>

    <h3>Priming</h3>
    <ul>
        <li><strong>Purpose:</strong> Seals the porous drywall mud to ensure uniform paint absorption and color.</li>
        <li><strong>Application:</strong> Evenly apply a quality primer over the sanded drywall for a smooth painting surface.</li>
    </ul>

    <h3>Safety Precautions</h3>
    <p>Always use protective eyewear and masks to prevent inhalation of dust. Ensure proper ventilation during the installation process to safeguard health.</p>
      </section>

      <section>
        <h2>Conclusion</h2>
        <p>Understanding each step from preparation to finishing ensures successful drywall installation, whether for functional or aesthetic purposes.</p>
      </section>
    </div>
  );
}

export default Drywall;
