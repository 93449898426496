import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Framing.css'; // Ensure this CSS file is properly set up

function Framing() {
  return (
    <div className="framing-container">
      <Link to="/">Back to Home</Link> {/* Replace the anchor tag with Link */}
      <h1>Complete Guide to Framing in Construction</h1>

      <section>
        <h2>Building a Metal Pergola</h2>
        <p>Explore the steps involved in building a metal pergola, a practical example of framing techniques. This video provides detailed instructions and tips for constructing a durable and aesthetically pleasing pergola.</p>
        
        <div className="video-affiliate-wrapper">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/YQXxY7UxrzY"
        title="Building a Metal Pergola"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
    </iframe>

    {/* Button for Amazon affiliate link */}
    <a href="https://www.amazon.com/Milwaukee-2745-21-30-Degree-Cordless-Framing/dp/B0872HQBKV?crid=1TJMFU6PO7N3G&keywords=Milwaukee+cordless+Framing+gun&qid=1704690408&sprefix=milwaukee+cordless+framing+gun%2Caps%2C153&sr=8-1&linkCode=ll1&tag=mrfixit09-20&linkId=cf034381c87763926a1786c277bb7ce6&language=en_US&ref_=as_li_ss_tl" 
       target="_blank" 
       rel="noopener noreferrer"
       style={{ display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '4px 2px', cursor: 'pointer' }}>
        Check out The Best Cordless Framing Gun on Amazon
    </a>
</div>

      </section>

      <section>
        <h2>Introduction</h2>
        <p>Framing forms the structural backbone of a house or building. Understanding the basics of framing is crucial for any construction project. This guide will cover key components such as headers, pressure-treated lumber, and considerations for insulation.</p>
      </section>

      <section>
    <h2>Beam Size and Span Guide</h2>
    <p>
        <strong>2x4:</strong> Typically used for interior non-load-bearing walls. Not usually used as headers in load-bearing walls.<br/>
        <strong>2x6:</strong> Can span up to 6 feet (1.8 meters) for a single-story building under normal loading conditions.<br/>
        <strong>2x8:</strong> Can span about 8 feet (2.4 meters) in a single-story building with normal load conditions.<br/>
        <strong>2x10:</strong> Can span up to 10 feet (3 meters) in a single-story structure under normal loading.<br/>
        <strong>2x12:</strong> Can span approximately 12 feet (3.7 meters) in a single-story building under normal loading conditions.<br/>
        <strong>4x6:</strong> Can typically span up to about 8 feet (2.4 meters) under normal loading conditions in a single-story building. Stronger and can carry more load compared to a 2x6.<br/>
        <strong>4x8:</strong> Can span up to about 10 feet (3 meters) under normal loading conditions in a single-story structure. Commonly used for larger rooms or for supporting heavier loads.<br/>
        <strong>4x10:</strong> Can span up to approximately 12 feet (3.7 meters) in a single-story building under normal loading. Offers significant support and is used in situations requiring extended spans without intermediate supports.<br/>
        <strong>4x12:</strong> Can span up to about 14 feet (4.3 meters) under normal load conditions in a single-story building. Often used in larger structures or where substantial loads are expected.
    </p>
</section>


      <section>
        <h2>Pressure-Treated Lumber for Foundations</h2>
        <p>
    The bottom plate of the wall frame, in contact with the foundation, should be made of pressure-treated lumber to prevent moisture damage and decay. Pressure-treated lumber is specially treated with preservatives to resist environmental elements, particularly moisture and insects, which makes it ideal for use in areas prone to these issues.

    When working with pressure-treated lumber, it's important to consider safety aspects. The chemicals used in the pressure treatment process can be harmful if not handled properly. Always wear gloves and a dust mask when cutting or handling this type of wood to avoid skin contact or inhalation of sawdust. Additionally, it's advisable to use galvanized or stainless steel fasteners with pressure-treated lumber, as the chemicals can corrode regular steel and iron fasteners over time.

    In terms of application, the pressure-treated bottom plate is typically laid directly on the foundation and anchored using bolts or nails, depending on the building code requirements and the type of foundation. It acts as a barrier between the potentially damp foundation and the rest of the wall framing, ensuring the integrity and longevity of the structure. When installing, ensure that the plate is level and properly aligned with the layout of the wall. This will form a solid and stable base for the subsequent framing work.
</p>

      </section>

      <section>
        <h2>Wall Thickness and Insulation</h2>
        <p>Wall framing with 2x4 or 2x6 studs affects insulation capacity. Thicker walls provide more insulation, improving energy efficiency.</p>
      </section>

      <section>
    <h2>Essential Tools and Tips for Efficient Framing in Construction</h2>
    <p>Framing is a crucial part of the construction process, serving as the skeleton for your building. It involves meticulous planning and precision, and the right tools can make a significant difference. In this article, we delve into the essential tools for framing and provide instructions and tips for efficient construction. We'll also discuss common framing mistakes and how to avoid them.</p>
    
    <h3>Key Framing Tools</h3>
    <ul>
        <li><strong>Tape Measure</strong>: The foundation of accurate framing. A reliable, long tape measure is indispensable.</li>
        <li><strong>Level</strong>: Ensures your frames are perfectly horizontal and vertical.</li>
        <li><strong>Chalk Line</strong>: Essential for marking long, straight lines on flat surfaces.</li>
        <li><strong>Circular Saw</strong>: A versatile tool for cutting lumber to the desired length.</li>
        <li><strong>Speed Square</strong>: Perfect for marking precise angles and lines.</li>
        <li><strong>Hammer</strong>: For traditional framing, a good hammer is a must-have for driving nails.</li>
        <li><strong>Nail Gun</strong>: For faster and more efficient work, a nail gun is invaluable. My personal favorite is the Milwaukee Cordless Framing Gun. It's a robust, reliable tool that significantly speeds up the framing process. I highly recommend it for its efficiency and ease of use.</li>
    </ul>
    
    <h3>Instructions and Tips for Efficient Framing</h3>
    <ol>
        <li><strong>Stud Layout</strong>: Begin by laying out your studs. Standard spacing is 16 inches on center, but check local codes.</li>
        <li><strong>Cutting to Length</strong>: Measure twice, cut once. Ensure all your framing lumber is cut accurately.</li>
        <li><strong>Assembling the Wall Frame</strong>: Lay out your plates and studs on a flat surface. Use the chalk line and speed square for alignment.</li>
        <li><strong>Checking for Square</strong>: Always check for squareness before securing the frame. Adjust as needed.</li>
        <li><strong>Securing the Frame</strong>: Use the nail gun or hammer to secure the studs to the plates. The Milwaukee Cordless Framing Gun can make this step much quicker and more efficient.</li>
        <li><strong>Raising the Wall</strong>: With a team, carefully raise the wall into place and secure it.</li>
    </ol>
    
    <h3>Common Framing Mistakes and How to Avoid Them</h3>
    <ul>
        <li><strong>Incorrect Spacing of Studs</strong>: Always double-check measurements before cutting or nailing.</li>
        <li><strong>Not Checking for Square</strong>: Regularly use your level and square to ensure accuracy.</li>
        <li><strong>Over/Under Cutting</strong>: Use precise measurements and markings for every cut.</li>
        <li><strong>Ignoring Local Building Codes</strong>: Always adhere to local regulations for spacing, sizes, and materials.</li>
    </ul>
    
    <p>Efficient framing is a blend of precision, proper tools, and adherence to best practices. By avoiding common mistakes and using high-quality tools like the Milwaukee Cordless Framing Gun, you can ensure a sturdy and reliable frame for your construction projects. Remember, good preparation and the right tools are the key to successful framing.</p>
</section>


      <section>
        <h2>Conclusion</h2>
        <p>Framing is a fundamental skill in construction. By understanding the components and techniques involved, you can ensure your project is sturdy, efficient, and up to code.</p>
      </section>
    </div>
  );
}

export default Framing;
