import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Molding.css'; // Make sure this CSS file is properly set up

function Molding() {
  return (
    <div className="molding-container">
      <Link to="/">Back to Home</Link> {/* Replace the anchor tag with Link */}
      <h1>Complete Guide to Molding in Home Improvement</h1>

      <section>
        <h2>Gluing Window and Door Casings</h2>
        <p>Discover the techniques for effectively gluing together window and door casings. This video provides practical tips for ensuring strong, durable joins in your molding projects.</p>
        
        <div className="video-affiliate-wrapper">
    <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/4zOZS3G8lvg"
        title="How to Glue Together Window and Door Casings"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
    </iframe>

    {/* Button for Amazon affiliate link */}
    <a href="https://www.amazon.com/Mercury-Adhesives-Starter-Pack-accelerator/dp/B06X6MBZ3C?crid=1J2OTAJT4JRPY&keywords=Mercury+two+part+glue+for+molding&qid=1704692418&sprefix=mercury+two+part+glue+for+molding%2Caps%2C196&sr=8-3&linkCode=ll1&tag=mrfixit09-20&linkId=91a1342ad000b2657db9040246799a2d&language=en_US&ref_=as_li_ss_tl" 
       target="_blank" 
       rel="noopener noreferrer"
       style={{ display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', textAlign: 'center', textDecoration: 'none', fontSize: '16px', borderRadius: '5px', margin: '4px 2px', cursor: 'pointer' }}>
        Check out This Two-Part Glue for Moldings on Amazon
    </a>
</div>

      </section>

      <section>
        <h2>Introduction</h2>
        <p>Molding plays a crucial role in adding character and elegance to a home. From base moldings to crown moldings and window casings, understanding your options and how to choose them is key. This guide will delve into the various aspects of molding.</p>
      </section>

      <section>
        <h2>Base Moldings</h2>
        <p>Purpose and Styles: Base moldings, running along the bottom of walls, add a finished look and protect the wall from damage. We'll cover the different styles and their aesthetic impacts. Size Considerations: The size of base moldings can vary. We'll discuss how to choose the right size based on room proportions and ceiling height.</p>
      </section>

      <section>
        <h2>Crown Moldings</h2>
        <p>Enhancing Room Aesthetics: Crown moldings, placed where walls meet the ceiling, can transform a room's appearance. We'll explore various design options and their effects. Sizing and Installation: Choosing the right size and understanding installation techniques are essential for a seamless look. We'll provide guidelines and tips.</p>
      </section>

      <section>
        <h2>Window Casings</h2>
        <p>Window Frame Enhancement: Window casings are the trim around windows, influencing both interior and exterior aesthetics. We'll discuss styles and installation methods. Material Choices: From traditional wood to modern composites, material selection affects the look and maintenance of window casings.</p>
      </section>

      <section>
        <h2>Popular Molding Materials</h2>
        <p>Wood Types: Popular wood types for moldings include pine, oak, and MDF. We'll explore each type's characteristics, benefits, and best uses. Finish and Paint Options: Choosing the right finish or paint for your molding is crucial. We'll cover how to select and apply finishes for durability and style.</p>
      </section>

      <section>
        <h2>Selecting Moldings for Your Home</h2>
        <p>Room Style and Decor: Moldings should complement the overall style of your room and home. We'll provide advice on matching moldings with your existing decor. Considerations for Different Spaces: Different areas of the home may require different molding approaches. We'll discuss how to make choices for each space.</p>
      </section>

      <section>
        <h2>Conclusion</h2>
        <p>Molding is an essential element in home design, capable of dramatically altering the feel of a space. Understanding the various types and their applications allows you to make informed decisions, enhancing your home's aesthetic appeal and value.</p>
      </section>
    </div>
  );
}

export default Molding;
